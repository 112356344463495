"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name.js");

require("core-js/modules/web.timers.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _AdTrack = _interopRequireDefault(require("@meepshop/context/lib/AdTrack"));

// import
// definition
var _default = function _default(_ref, custom) {
  var name = _ref.name,
      category = _ref.category;
  var adTrack = (0, _react.useContext)(_AdTrack["default"]);

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      isTriggered = _useState2[0],
      setIsTriggered = _useState2[1];

  var visibleChange = (0, _react.useCallback)(function (visible) {
    if (isTriggered || !visible) return; // 因 window.fbq & window.gtag 需要時間載入，故 setTimeout，否則會有最上層資料沒被追蹤到

    setTimeout(function () {
      return adTrack.custom(custom || 'meepShop_view', name, category);
    }, 1500);
    setIsTriggered(true);
  }, [name, category, adTrack, isTriggered, custom]);
  return {
    isTriggered: isTriggered,
    visibleChange: visibleChange
  };
};

exports["default"] = _default;